import React from 'react';
import Layout from '../components/Layout';
import Hero from '../components/ui/Hero/Hero';
import Section from '../components/ui/Section/Section';
import * as styles from './kontakt.module.scss';
import HorizontalScroll from '../components/ui/HorizontalScroll/HorizontalScroll';
import Card from '../components/ui/Card/Card';
import ButtonAction from '../components/ui/Buttons/ButtonAction';

import Phone  from '../assets/phone.inline.svg';
import Mail  from '../assets/mail.inline.svg';
import Chat  from '../assets/chat.inline.svg';
import useGdprIsAllowed from '../components/Gdpr/useGdprIsAllowed';
import GdprAllowButton from '../components/Gdpr/GdprAllowButton';

export default function Kontakt () {

    const isChatAllowed = useGdprIsAllowed("Chat");
    
    const openChat = () => {
        if (typeof window !== `undefined`) {
            window.RocketChat(function() {
                this.showWidget();
                this.maximizeWidget();
            });
        }
    }

    const sendMail = () => {
        if (typeof window !== `undefined`) {
            window.open('mailto:info@hisprint.de', '_self');
        }
    }

    const phoneUs = () => {
        if (typeof window !== `undefined`) {
            window.open('tel:00498283998100', '_self');
        }
    }

    return <Layout
        crumbs={[
            { label: "Home", url: "" },
            { label: "Kontakt", url: "kontakt" },
        ]}
        breadcrumbColor="light"
        hideContact={true}
        hasWave={false}
        headerColor={"dark"}
    >
            <Hero
                title={"Guten Tag! Wie können wir behilflich sein?"} 
                subtitle={"Es gibt für alles eine Lösung! Sie ist bis jetzt nur noch nicht hier aufgelistet. Wir finden immer das richtige Produkt mit der besten Beschriftung."}
                color="dark"
                hasWaveBottom={["mobile", "tablet"]}
            >
            </Hero>

            <Section size="small"  className={styles.contactSelection} hasTitle={true}>
                <div className="content">
                    <h2 className="title">Kontaktieren Sie uns</h2>
                    <p>
                        Wir sind Montags bis Donnerstags von 08:00 bis 17:00 Uhr und<br />
                        Freitags von 08:00 bis 13:00 Uhr für Sie da!
                    </p>
                </div>
            </Section>
            
            <Section>
                <HorizontalScroll>
                    <div className="columns has-flick-columns-tablet">
                        <div className="column">
                            <Card>
                                <div className="content">
                                    <figure className="image is-128x128">
                                        <Chat />
                                    </figure>
                                    <h3>Chatten Sie mit uns</h3>
                                    <p>
                                        Direkt und unkompliziert
                                    </p>
                                    {isChatAllowed && <ButtonAction onClick={openChat}>Chat starten</ButtonAction>}
                                    {!isChatAllowed && <>
                                        <GdprAllowButton serviceKey="Chat"><ButtonAction onClick={() => { setTimeout(() => openChat(), 500)}}>Chat erlauben & starten</ButtonAction></GdprAllowButton>
                                        <i className="is-size-7">Beim klicken auf den Button ändern sich Ihre Privatsphäreeinstellungen um den Chat zu erlauben.</i>
                                    </>}
                                </div>
                            </Card>
                        </div>
                        <div className="column">
                            <Card>
                            <div className="content">
                                    <figure className="image is-128x128">
                                        <Phone />
                                    </figure>
                                    <h3>Rufen Sie uns an</h3>
                                    <p>
                                        +49 (0)82 83 / 9 98 10 - 0
                                    </p>
                                    <ButtonAction onClick={phoneUs}>Jetzt anrufen</ButtonAction>
                                </div>
                            </Card>
                        </div>
                        <div className="column">
                            <Card>
                                <div className="content">
                                    <figure className="image is-128x128">
                                            <Mail />
                                    </figure>
                                    <h3>Schreiben Sie uns eine E-Mail</h3>
                                    <p>
                                        info@his-print-service.de
                                    </p>
                                    <ButtonAction onClick={sendMail}>E-Mail schreiben</ButtonAction>
                                </div>
                                
                            </Card>
                        </div>
                    </div>
                </HorizontalScroll>

            </Section>

    </Layout>
}